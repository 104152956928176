import { useState } from "react";

import {
  ALGERIA,
  DZ_YOUTUBE_INTRODUCTION_VIDEO_ID,
  YOUTUBE_INTRODUCTION_VIDEO_ID,
} from "~/config/constants";
import "./IntroVideo.scss";
import { useCountry } from "~/context/CountryContext";

export default function IntroVideo() {
  const [showModal, setShowModal] = useState(false);
  const { country } = useCountry();

  let youtubeVideoId = YOUTUBE_INTRODUCTION_VIDEO_ID;
  if (country === ALGERIA) {
    youtubeVideoId = DZ_YOUTUBE_INTRODUCTION_VIDEO_ID;
  }

  const openVideoModal = (event: React.MouseEvent) => {
    event.preventDefault();
    setShowModal(true);
  };

  const closeVideoModal = (event: React.MouseEvent) => {
    event.preventDefault();
    setShowModal(false);
    const html = document.querySelector("html") as HTMLElement;
    html.classList.remove("overflow-hidden");
  };

  return (
    <div className="course-intro-video cs-video_block_1_wrap">
      <div className="container">
        <a
          href={`https://youtu.be/${youtubeVideoId}`}
          className="cs-video_block cs-style1 cs-video_open cs-bg"
          style={{ backgroundImage: "url(/img/video_bg.webp)" }}
          onClick={openVideoModal}
        >
          <span className="cs-player_btn cs-accent_color">
            <span></span>
          </span>
        </a>
      </div>

      <div className={"cs-video_popup " + (showModal ? "active" : "")}>
        <div className="cs-video_popup_overlay"></div>
        <div className="cs-video_popup_content">
          <div
            className="cs-video_popup_layer"
            onClick={closeVideoModal}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
          ></div>
          <div className="cs-video_popup_container">
            <div className="cs-video_popup_align">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src={
                    showModal
                      ? `https://www.youtube.com/embed/${youtubeVideoId}`
                      : "about:blank"
                  }
                  title="Introduction Youtube Video"
                ></iframe>
              </div>
            </div>
            <div
              className="cs-video_popup_close"
              onClick={closeVideoModal}
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
